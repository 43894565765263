import React, { useState } from "react";
import Cell from "./Cell";
import jsonPrettier from "../services/jsonPrettier";
import Modal from "./Modal";
import Button from "./Button";

const JSONCell = ({ value, inputsProps }) => {
  const [valueState, setValueState] = useState(jsonPrettier(value || "{}"));
  const [valueEditor, setValueEditor] = useState(jsonPrettier(value || "{}"));
  const [isValid, setIsValid] = useState(true);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValueEditor(valueState);
    setIsValid(true);
    setOpen(false);
  };

  const handleCloseAndSave = () => {
    if (!isValid) return;
    setValueState(valueEditor);
    setOpen(false);
  };

  return (
    <Cell className='json'>
      <input
        type='hidden'
        className='nativeInput'
        name={inputsProps.name}
        value={JSON.stringify(JSON.parse(valueState))}
      />
      <div className='jsonView' onClick={handleOpen}>
        {valueState}
      </div>

      <Modal open={open} handleClose={handleClose}>
        <h2>&Eacute;diteur JSON</h2>
        <textarea
          value={valueEditor}
          onChange={(e) =>
            setValueEditor(jsonPrettier(e.target.value, setIsValid))
          }
          className={
            isValid
              ? "jsonTextarea bigTextarea "
              : "jsonTextarea bigTextarea invalid"
          }
        />
        <div className={`editorMessage ${isValid ? "" : "invalid"}`}>
          JSON invalide
        </div>
        <Button
          onClick={handleCloseAndSave}
          className={`button ${isValid ? "" : "invalid"}`}
        >
          Terminer
        </Button>
        <Button onClick={handleClose}>Annuler</Button>
      </Modal>
    </Cell>
  );
};

export default JSONCell;
