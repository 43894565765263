import React, { useEffect, useRef, useState } from "react";

const ScrollHorizontal = ({ children }) => {
  const scrollRef = useRef(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleWheel = (event) => {
      if (isShiftPressed) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("wheel", handleWheel);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      scrollElement.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isShiftPressed]);

  return (
    <div className='scrollHorizontal' ref={scrollRef}>
      {children}
    </div>
  );
};

export default ScrollHorizontal;
