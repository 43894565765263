import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import CRUD from "./pages/CRUD";
import "./App.css";
import ProtectedRoute from "./services/ProtectedRoute";
import handleLogin from "./services/handleLogin";
import Formules from "./pages/Formules";
import Logs from "./pages/Logs";
import Logout from "./pages/Logout";
import CRUDHome from "./pages/CRUD_home";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path='/login'
          element={<LoginPage onLogin={handleLogin} />}
        />
        <Route
          element={
            <ProtectedRoute
              user={localStorage.getItem("userToken") ? true : false}
            />
          }
        >
          <Route path='/' element={<HomePage />} />
          <Route path='/read/:section' element={<CRUD />} />
          <Route path='/read' element={<CRUDHome />} />
          <Route path='/formules' element={<Formules />} />
          <Route path='/logs' element={<Logs />} />
          <Route path='/logout' element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
