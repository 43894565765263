const Modal = ({ open, handleClose, children, style = {} }) => {
  return (
    <div className={`modal ${open ? "show" : "initial"}`} onClick={handleClose}>
      <div
        className='modalContent'
        onClick={(e) => e.stopPropagation()}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
