import React, { useState, useEffect } from "react";
import Cell from "./Cell";

const StringCell = ({ value, type = "text", inputsProps = {} }) => {
  const [valueState, setValueState] = useState(value);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (inputsProps.regex) {
      try {
        const regex = new RegExp(inputsProps.regex, inputsProps.regex_flags);
        setIsValid(regex.test(valueState));
      } catch (e) {
        console.error("Invalid regular expression: ", e);
        setIsValid(false);
      }
    }
  }, [valueState, inputsProps?.regex, inputsProps?.regex_flags]);

  return (
    <Cell>
      {inputsProps.regex &&
      inputsProps.regex.match(new RegExp("([A-Za-z_]){2,50}", "g")) ? (
        <select
          value={valueState}
          className='nativeInput'
          name={inputsProps.name}
          onChange={(e) => setValueState(e.target.value)}
        >
          {inputsProps.regex &&
            inputsProps.regex
              .match(new RegExp("([A-Za-z_]){2,50}", "g"))
              .map((list) => (
                <option key={list} value={list} selected={list === valueState}>
                  {list}
                </option>
              ))}
        </select>
      ) : (
        <input
          type={type}
          value={valueState}
          name={inputsProps.name}
          onChange={(e) => setValueState(e.target.value)}
          className={isValid ? "nativeInput" : "nativeInput invalid"}
        />
      )}
    </Cell>
  );
};
export default StringCell;
