import React, { useState, useEffect } from "react";
import Cell from "./Cell";
import fetchData from "../services/fetchData";

const IntCell = ({ value, inputsProps }) => {
  const [valueState, setValueState] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [endpoint, setEndpoint] = useState("");
  const [endpointData, setEndpointData] = useState(null);

  useEffect(() => {
    if (inputsProps.related_to !== "") {
      setEndpoint(inputsProps.related_to.split(".")[0]);
    }
  }, [inputsProps.related_to]);

  useEffect(() => {
    if (endpoint !== "") {
      fetchData({
        endpoint: `/${endpoint}`,
        saveIn: "data_" + endpoint,
      }).then((response) => {
        if (response.operation === "success") {
          setEndpointData(response);
        } else {
          console.error("Error fetch related : ");
        }
      });
    }
  }, [endpoint]);

  useEffect(() => {
    if (inputsProps.regex) {
      try {
        const regex = new RegExp(inputsProps.regex, inputsProps.regex_flags);
        setIsValid(regex.test(valueState));
      } catch (e) {
        setIsValid(false);
      }
    }
  }, [valueState, inputsProps.regex, inputsProps.regex_flags]);

  return (
    <Cell>
      {inputsProps.related_to === "" ? (
        <input
          type='number'
          value={valueState}
          name={inputsProps.name}
          onChange={(e) => setValueState(e.target.value)}
          className={isValid ? "nativeInput" : "nativeInput invalid"}
        />
      ) : (
        <select
          value={valueState}
          className='nativeInput'
          name={inputsProps.name}
          onChange={(e) => setValueState(e.target.value)}
        >
          {endpointData !== null &&
            Object.values(endpointData?.data).length > 0 &&
            endpointData?.data.map((el) => (
              <option key={el.id} value={el.id}>
                {el.label || el.name || el.id}
              </option>
            ))}
        </select>
      )}
    </Cell>
  );
};

export default IntCell;
