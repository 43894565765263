// here, distroy  all localStorage and redirect to login page

import React from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Title from "../components/Title";

const Logout = () => {
  localStorage.clear();
  window.location.replace("/");

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>Deconnexion</Title>
      </div>
    </LayoutPrimary>
  );
};

export default Logout;
