import React, { useEffect, useState } from "react";
import fetchData from "../services/fetchData";
import Title from "../components/Title";
import LayoutPrimary from "../components/partials/LayoutPrimary";

const HomePage = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetchData({
        endpoint: "/users",
        method: "GET",
        saveIn: "userData",
      });
      if (response.operation === "success") {
        setUserData(response.data);
      } else {
        window.location.replace("/login");
      }
    };
    fetchUserData();
  }, [setUserData]);

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>Accueil</Title>
        {console.log(userData)}
      </div>
    </LayoutPrimary>
  );
};

export default HomePage;
