import Cell from "./Cell";

const NeutralCell = ({ value, inputsProps }) => {
  return (
    <Cell className='neutralCell'>
      {value}
      <input type='hidden' value={value} name={inputsProps?.name} />
    </Cell>
  );
};

export default NeutralCell;
