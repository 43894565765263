const Navigation = () => {
  return (
    <nav>
      <a href='/'>Accueil</a>
      <a href='/read'>Base de données</a>
      <a href='/formules'>Formules</a>
      <a href='/logs'>Logs</a>
      <a href='/logout'>Déconnexion</a>
    </nav>
  );
};

export default Navigation;
