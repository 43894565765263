import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Title from "../components/Title";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import fetchData from "../services/fetchData";
import deleteData from "../services/deleteData";
import postData from "../services/postData";
import updateData from "../services/updateData";
import IntCell from "../components/IntCell";
import StringCell from "../components/StringCell";
import DateCell from "../components/DateCell";
import BoolCell from "../components/BoolCell";
import JSONCell from "../components/JSONCell";
import NeutralCell from "../components/NeutralCell";
import FloatCell from "../components/FloatCell";
import getName from "../services/getName";
import Button from "../components/Button";
import ScrollHorizontal from "../components/ScrollHorizontal";
import parseTr from "../services/parseTr";

const CRUD = () => {
  const [dataCurrent, setDataCurrent] = useState([]);
  const [structure, setStructure] = useState([]);
  const { section } = useParams();
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    fetchData({
      endpoint: "/about",
      method: "GET",
      saveIn: "endpoints",
    }).then((response) => {
      if (response.operation === "success") {
        setEndpoints(response.data);
      }
    });
  }, [setEndpoints]);

  useEffect(() => {
    fetchData({
      endpoint: `/about/${section}`,
      saveIn: `structure_${section}`,
    }).then((response) => {
      if (response.operation === "success") {
        setStructure(response.data);
      } else {
        alert(response.errorMessage);
      }
    });

    fetchData({
      endpoint: `/${section}`,
      saveInNotRead: "data_" + section,
    })
      .then((response) => {
        console.log(response);
        if (response?.operation === "success") {
          setDataCurrent(response.data);
        } else {
          console.log(response.errorMessage);
        }
      })
      .catch((error) => {
        alert("Une erreur est survenue. \n En savoir plus : " + error);
      });
  }, [section]);

  const handlePost = (e) => {
    postData({
      endpoint: `/${section}`,
      body: JSON.stringify(parseTr(document.querySelector(".addTr"))),
    })
      .then((response) => {
        if (response.operation === "success") {
          fetchData({
            endpoint: `/${section}`,
            saveInNotRead: "data_" + section,
          })
            .then((response) => {
              if (response?.operation === "success") {
                setDataCurrent(response.data);
              } else {
                alert(response?.errorMessage);
              }
            })
            .catch((error) => {
              alert("Une erreur est survenue. \n En savoir plus : " + error);
            });
        } else {
          alert(response.errorMessage);
        }
      })
      .catch((error) => {
        alert("Une erreur est survenue. \n En savoir plus : " + error);
      });
  };

  const handlePut = (e) => {
    updateData({
      endpoint: `/${section}/${e.target.closest("tr").getAttribute("data-id")}`,
      body: JSON.stringify(parseTr(e.target.closest("tr"))),
    })
      .then((response) => {
        if (response.operation === "success") {
          fetchData({
            endpoint: `/${section}`,
            saveInNotRead: "data_" + section,
          })
            .then((response) => {
              if (response?.operation === "success") {
                setDataCurrent(response.data);
              } else {
                alert(response?.errorMessage);
              }
            })
            .catch((error) => {
              alert("Une erreur est survenue. \n En savoir plus : " + error);
            });
        } else {
          alert(response.errorMessage);
        }
      })
      .catch((error) => {
        alert("Une erreur est survenue. \n En savoir plus : " + error);
      });
  };

  const handleDelete = (e) => {
    deleteData({
      endpoint: `/${section}/${e.target.closest("tr").getAttribute("data-id")}`,
    }).then((response) => {
      if (response.operation === "success") {
        fetchData({
          endpoint: `/${section}`,
          saveInNotRead: "data_" + section,
        })
          .then((response) => {
            if (response?.operation === "success") {
              setDataCurrent(response.data);
            } else {
              alert(response?.errorMessage);
            }
          })
          .catch((error) => {
            alert("Une erreur est survenue. \n En savoir plus : " + error);
          });
      } else {
        alert(response.errorMessage);
      }
    });
  };

  const toHide = ["about", "auth", "base_cfe", "compute"];

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>{getName(section)}</Title>
      </div>
      <div className='subMenu'>
        <p>
          <strong>Aller à :</strong>
        </p>
        {endpoints?.map(({ object }) => {
          if (!toHide.includes(object)) {
            return (
              <a href={"/read/" + object} title={"Aller à " + object}>
                {getName(object)}
              </a>
            );
          }
          return null;
        })}
      </div>
      <h2>Consulter</h2>
      <ScrollHorizontal>
        <table className='table mt5'>
          <thead>
            <tr>
              {dataCurrent.length > 0 &&
                structure.length > 0 &&
                Object.keys(dataCurrent[0]).map((key) => (
                  <th>{getName(key)}</th>
                ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataCurrent.length > 0 &&
              structure.length > 0 &&
              dataCurrent.map((item) => (
                <tr key={item.id} data-id={item.id || item.name || item.label}>
                  {Object.entries(item).map((entries) => {
                    const inputsProps = structure
                      ?.filter((s) => s.verb === "POST")[0]
                      ?.inputs.filter((i) => i.name === entries[0])[0];
                    const type = inputsProps?.type;

                    switch (type) {
                      case "INT":
                        return (
                          <IntCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      case "FLOAT":
                        return (
                          <FloatCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      case "DATETIME":
                        return (
                          <DateCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      case "BOOL":
                        return (
                          <BoolCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      case "STRING":
                        return (
                          <StringCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      case "JSON":
                        return (
                          <JSONCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );

                      default:
                        return (
                          <NeutralCell
                            key={entries[0]}
                            value={entries[1]}
                            inputsProps={inputsProps}
                          />
                        );
                    }
                  })}
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {structure?.filter((s) => s.verb === "PUT")[0] && (
                        <Button className='button' onClick={handlePut}>
                          Modifier
                        </Button>
                      )}
                      {structure?.filter((s) => s.verb === "DELETE")[0] && (
                        <Button className='button' onClick={handleDelete}>
                          Supprimer
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            {Object.values(dataCurrent).length === 0 && (
              <tr>
                <td>
                  <p>
                    <em>Aucune données disponible</em>
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScrollHorizontal>
      {structure?.filter((s) => s.verb === "POST")[0] && (
        <div>
          <h2>Ajouter</h2>
          <ScrollHorizontal>
            <table className='table mt5 inputShow'>
              <thead>
                <tr>
                  {structure.length > 0 &&
                    structure
                      ?.filter((s) => s.verb === "POST")[0]
                      ?.inputs.map((input) => <th>{getName(input.name)}</th>)}
                </tr>
              </thead>
              <tbody>
                <tr key='add' className='addTr'>
                  {structure.length > 0 &&
                    structure
                      ?.filter((s) => s.verb === "POST")[0]
                      ?.inputs?.map((input) => {
                        if (input.name === "id" || input.name === "created")
                          return (
                            <NeutralCell key={input.name} value={"AUTO"} />
                          );

                        if (input?.name === "password")
                          return (
                            <StringCell
                              type='password'
                              value=''
                              inputsProps={{
                                ...input,
                                regex: undefined,
                                regex_flags: undefined,
                              }}
                            />
                          );

                        switch (input.type) {
                          case "INT":
                            return (
                              <IntCell
                                key={input.name}
                                value=''
                                inputsProps={input}
                              />
                            );

                          case "FLOAT":
                            return (
                              <FloatCell
                                key={input.name}
                                value=''
                                inputsProps={input}
                              />
                            );

                          case "DATETIME":
                            return (
                              <DateCell
                                key={input.name}
                                value=''
                                inputsProps={input}
                              />
                            );

                          case "BOOL":
                            return (
                              <BoolCell
                                key={input.name}
                                value=''
                                inputsProps={input}
                              />
                            );

                          case "STRING":
                            return (
                              <StringCell
                                key={input.name}
                                value=''
                                inputsProps={input}
                              />
                            );

                          case "JSON":
                            return (
                              <JSONCell
                                key={input.name}
                                value='{}'
                                inputsProps={input}
                              />
                            );

                          default:
                            return <StringCell key={input.name} value='' />;
                        }
                      })}
                </tr>
              </tbody>
            </table>
          </ScrollHorizontal>
          <br />
          <br />
          <div
            className='center'
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button className='button' onClick={handlePost}>
              Ajouter
            </Button>
          </div>
        </div>
      )}
    </LayoutPrimary>
  );
};

export default CRUD;
