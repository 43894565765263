import Title from "../components/Title";
import LayoutPrimary from "../components/partials/LayoutPrimary";

const Logs = () => {
  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>Logs</Title>
      </div>
      <div className='subMenu'>
        <p>
          <strong>Aller à :</strong>
        </p>
        <span>Aucun log disponible</span>
      </div>
      <h2>Consulter</h2>
      <table className='table mt5'>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                <em>Selectionnez un log</em>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </LayoutPrimary>
  );
};

export default Logs;
