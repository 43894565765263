import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import updateData from "../services/updateData";
import fetchData from "../services/fetchData";

const FormuleView = ({ formula }) => {
  const [formule, setFormule] = useState(formula.formule);
  const [open, setOpen] = useState(false);
  const [structure, setStructure] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };

  const saveFormule = () => {
    updateData({
      endpoint: `/compute/${formula.label}`,
      method: "PUT",
      body: JSON.stringify({
        label: formula.label,
        formule: formule,
      }),
    });
    setOpen(false);
  };

  useEffect(() => {
    fetchData({
      endpoint: "/about",
      saveIn: "structure",
    }).then((response) => {
      if (response.operation === "success") {
        setStructure(response.data);
      }
    });
  }, []);

  let outputs = [];

  return (
    <div key={formula.label}>
      <h3>{formula.label}</h3>
      <div className='formuleView' onClick={() => setOpen(true)}>
        <pre>
          {formula.formule.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </pre>
      </div>
      <Modal
        open={open}
        handleClose={handleClose}
        style={{ display: "flex", flexDirection: "row", gap: "20px" }}
      >
        <div style={{ flex: "4 1 0%", maxWidth: "80%" }}>
          <h2>{formula.label}</h2>
          <CodeMirror
            value={formula.formule}
            extensions={[javascript()]}
            basicSetup={{ foldGutter: false, lineNumbers: false }}
            onChange={(value) => setFormule(value)}
          />
          <button onClick={saveFormule}>Enregistrer</button>
          <button onClick={handleClose}>Annuler</button>
        </div>
        <div
          style={{ flex: "2 1 0%", maxHeight: "600px", overflow: "auto" }}
          className='memoView'
        >
          <h2>Fonctions valeur unitaire</h2>
          <ul>
            <li>
              jsonGet(<em>valeur JSON</em>) : <em>liste</em>
            </li>
            <li>
              if(<em>condition</em>) &#123; <em>code</em> &#125; else &#123;{" "}
              <em>code</em> &#125;
            </li>
          </ul>
          <h2>Opérations</h2>
          <ul>
            <li>
              <strong>Puissance</strong> : <em>unitaire</em> ** <em>entier</em>
            </li>
            <li>
              <strong>Multiplication</strong> : <em>unitaire</em> *{" "}
              <em>unitaire</em>{" "}
            </li>
            <li>
              <strong>Division</strong> : <em>unitaire</em> / <em>unitaire</em>{" "}
            </li>
            <li>
              <strong>Moins</strong> : <em>unitaire</em> - <em>unitaire</em>{" "}
            </li>
            <li>
              <strong>Plus</strong> : <em>unitaire</em> + <em>unitaire</em>{" "}
            </li>
          </ul>
          <h2>Conditions</h2>
          <ul>
            <li>
              <strong>Et</strong> : <em>unitaire</em> && <em>unitaire</em>
            </li>
            <li>
              <strong>ou</strong> : <em>unitaire</em> || <em>unitaire</em>
            </li>
            <li>
              <strong>non</strong> : !<em>unitaire</em>
            </li>
            <li>
              <strong>egal</strong> : <em>unitaire</em> == <em>unitaire</em>
            </li>
            <li>
              <strong>différent</strong> : <em>unitaire</em> !={" "}
              <em>unitaire</em>
            </li>
            <li>
              <strong>inférieur</strong> : <em>unitaire</em> &lt;{" "}
              <em>unitaire</em>
            </li>
            <li>
              <strong>inférieur ou égal</strong> : <em>unitaire</em> &lt;={" "}
              <em>unitaire</em>
            </li>
          </ul>
          <h2>Unitaire</h2>
          {["business", "diplomas", "jobs", "users"].map((endpoint) => {
            const endpointMatch = structure.find((e) => e.object === endpoint);
            return (
              <div key={endpoint}>
                <h3>{endpoint}</h3>
                <ul>
                  {Array.isArray(endpointMatch?.outputs)
                    ? endpointMatch?.outputs?.map((e) => {
                        if (
                          e.type === "STRING" ||
                          e.type === "INT" ||
                          e.type === "FLOAT" ||
                          e.type === "DATE" ||
                          e.type === "JSON"
                        ) {
                          outputs.push(`${endpoint}_${e.name}`);
                          return (
                            <li key={e.name}>
                              {endpoint}_{e.name}
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null}
                </ul>
              </div>
            );
          })}

          <h2>Fonction liste de valeurs</h2>
          <ul>
            <li>
              max(<em>liste</em>) : <em>unitaire</em>
            </li>
            <li>
              min(<em>liste</em>) : <em>unitaire</em>
            </li>
            <li>
              sum(<em>liste</em>) : <em>unitaire</em>
            </li>
            <li>
              count(<em>liste</em>) : <em>unitaire</em>
            </li>
            <li>
              avg(<em>liste</em>) : <em>unitaire</em>
            </li>
            <li>
              percentile(<em>liste</em>, <em>int</em>) : <em>unitaire</em>
            </li>
            <li>
              foreach(<em>liste</em>) &#123; <em>code</em> &#125;
            </li>
          </ul>

          <h2>Liste de valeurs</h2>
          {[
            "absorptions",
            "activities",
            "answers",
            "business_answers",
            "charges",
            "users_answers",
          ].map((endpoint) => {
            const endpointMatch = structure.find((e) => e.object === endpoint);
            return (
              <div key={endpoint}>
                <h3>{endpoint}</h3>
                <ul>
                  {Array.isArray(endpointMatch?.outputs)
                    ? endpointMatch?.outputs?.map((e) => {
                        if (e.type === "INT" || e.type === "FLOAT") {
                          outputs.push(`${endpoint}_${e.name}`);
                          return (
                            <li key={e.name}>
                              {endpoint}_{e.name}
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null}
                </ul>
              </div>
            );
          })}
          <h2>JS</h2>
          <ul>
            <li>let {outputs.join(", ")}</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default FormuleView;
