const jsonPrettier = (value, callback = () => {}) => {
  try {
    const obj = JSON.parse(value);
    callback(true);
    return JSON.stringify(obj, undefined, 4);
  } catch (e) {
    callback(false);
    return value;
  }
};

export default jsonPrettier;
