const Button = ({ value, type, children, className, onClick }) => {
  return (
    <div
      className={
        className
          ? `${className} ${
              type ? type + "ButtonComponent" : "primaryButtonComponent"
            }`
          : type
          ? type + "ButtonComponent"
          : "primaryButtonComponent"
      }
    >
      <button onClick={onClick}>{value ? value : children}</button>
    </div>
  );
};

export default Button;
