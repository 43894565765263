const parseTr = (tr) => {
  const inputs = tr.querySelectorAll(".nativeInput");
  const data = {};
  inputs.forEach((td) => {
    if (td.value === "") {
      return;
    }
    data[td.name] = td.value;
  });
  return data;
};

export default parseTr;
