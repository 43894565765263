import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import fetchData from "../services/fetchData";
import getName from "../services/getName";

const CRUDHome = () => {
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    fetchData({
      endpoint: "/about",
      method: "GET",
      saveIn: "endpoints",
    }).then((response) => {
      if (response.operation === "success") {
        setEndpoints(response.data);
      }
    });
  }, [setEndpoints]);

  const toHide = ["about", "auth", "base_cfe", "compute"];

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>Base de données</Title>
      </div>
      <div className='subMenu'>
        <p>
          <strong>Aller à :</strong>
        </p>
        {endpoints?.map(({ object }) => {
          if (!toHide.includes(object)) {
            return (
              <a href={"/read/" + object} title={"Aller à " + object}>
                {getName(object)}
              </a>
            );
          }
          return null;
        })}
      </div>
      <h2>Consulter</h2>
      <table className='table mt5'>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className='center'>
                <em>Selectionnez une table</em>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </LayoutPrimary>
  );
};

export default CRUDHome;
