import React, { useState, useEffect } from "react";
import Cell from "./Cell";

const FloatCell = ({ value, inputsProps }) => {
  const [valueState, setValueState] = useState(value);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (inputsProps.regex) {
      try {
        const regex = new RegExp(inputsProps.regex, inputsProps.regex_flags);
        setIsValid(regex.test(valueState));
      } catch (e) {
        console.error("Invalid regular expression: ", e);
        setIsValid(false);
      }
    }
  }, [valueState, inputsProps.regex, inputsProps.regex_flags]);

  return (
    <Cell>
      <input
        type='number'
        value={valueState}
        onChange={(e) => setValueState(e.target.value)}
        name={inputsProps.name}
        className={isValid ? "nativeInput" : "nativeInput invalid"}
      />
    </Cell>
  );
};

export default FloatCell;
