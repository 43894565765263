import React, { useState } from "react";
import Cell from "./Cell";
import Button from "./Button";
import Modal from "./Modal";
import { DtCalendar } from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/style.css";

const DateCell = ({ value, inputsProps }) => {
  const [valueState, setValueState] = useState(value || "");
  const [valueEditor, setValueEditor] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [open, setOpen] = useState(false);

  const date = new Date(valueState);

  // Extract year, month, and day
  const year = date.getFullYear() || "";
  const month = String(date.getMonth() + 1).padStart(2, "0") || ""; // Months are zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0") || "";

  const displayValue = year === "" ? "" : `${year}-${month}-${day}`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValueEditor(valueState);
    setIsValid(true);
    setOpen(false);
  };

  const handleCloseAndSave = () => {
    if (!isValid) return;
    setValueState(
      `${valueEditor.year}-${valueEditor.month > 9 ? "" : "0"}${
        valueEditor.month
      }-${valueEditor.day > 9 ? "" : "0"}${valueEditor.day}`
    );
    setOpen(false);
  };

  return (
    <Cell>
      <input
        type='hidden'
        className='nativeInput'
        name={inputsProps.name}
        value={displayValue}
      />
      <div className='dateCell' onClick={handleOpen}>
        {displayValue}
      </div>
      <Modal open={open} handleClose={handleClose}>
        <h2>Saisir une date</h2>
        <DtCalendar
          onChange={setValueEditor}
          initValue={{ year, month, day }}
        />
        <Button
          onClick={handleCloseAndSave}
          className={`button ${isValid ? "" : "invalid"}`}
        >
          Terminer
        </Button>
        <Button onClick={handleClose}>Annuler</Button>
      </Modal>
    </Cell>
  );
};

export default DateCell;
