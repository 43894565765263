import React, { useEffect, useState } from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Title from "../components/Title";
import fetchData from "../services/fetchData";
import FormuleView from "../components/FormuleView";

const Formules = () => {
  const [dataCurrent, setDataCurrent] = useState([]);

  useEffect(() => {
    fetchData({
      endpoint: "/compute",
      saveInNotRead: "compute",
    }).then((response) => {
      if (response.operation === "success") {
        console.log(response.data);
        setDataCurrent(response.data);
      }
    });
  }, []);

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>Formules</Title>
      </div>

      <h2>Editeur de formules</h2>

      {Object.values(dataCurrent).map((formula) => (
        <FormuleView formula={formula} />
      ))}

      {/**structure.map((endpoint) => (
        <div>
          <h3>{endpoint.object}</h3>
          <ul>
            {Object.values(endpoint.outputs)?.map((output) => (
              <li key={output.name}>{output.name}</li>
            ))}
          </ul>
        </div>
      ))*/}
    </LayoutPrimary>
  );
};

export default Formules;
