import React, { useState, useEffect } from "react";
import fetchData from "../services/fetchData";

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      window.location.replace("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetchData({
      endpoint: "/auth",
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.operation === "success" && response.data.level === "admin") {
        // Login successful

        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("userData", JSON.stringify(response));
        onLogin();
      } else {
        // Login failed
        setError("Email ou mot de passe incorrect.");
      }
    });
  };

  return (
    <div className='loginContainer'>
      <img src='http://doc.greadn.com/assets/logo-ico.svg' alt='Login Greadn' />
      <h2>Connexion</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor='email'>Email</label>
          <input
            type='text'
            id='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            id='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='buttonContainer'>
          <button type='submit'>Login</button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
