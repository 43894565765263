const Title = ({ title, children, className }) => {
  return (
    <div className={className}>
      <h1 className='titleFixed'>{title ? title : children}</h1>
    </div>
  );
};

Title.defaultProps = {
  className: "",
};

export default Title;
